var is_mobile = window.matchMedia('all and (max-width: 1230px)').matches;
var is_mac = check_mac();
$(function () {

	if (is_mac) {
		$('body').addClass('is-mac');
	}
	//Кастомизация чекбоксов
	// init_radio_style();


	


	// $(".feedback-itm").on("click", function () {
	// 	var items = $(this).closest(".feedback-itm-list").find(".feedback-itm");

	// 	items.removeClass("active");
	// 	$(this).addClass("active");
	// });

	


	$(window).on('scroll', function () {
		var scroll = $(window).scrollTop();

		if (scroll > 0) {
			$('.header').addClass('fixed');
		} else {
			$('.header').removeClass('fixed');
		}

	});





	$(window).on('resize', function () {
		is_mobile = window.matchMedia('all and (max-width: 1230px)').matches;
	});


	// $('.search-block input').on('focus', function () {
	// 	$(this).parent().addClass('focus');
	// });

	// $('.search-block input').on('blur', function () {
	// 	var self = $(this);
	// 	setTimeout(function () {
	// 		self.parent().removeClass('focus');

	// 	}, 150);
	// });

	// $('.search-block input').on('input', function () {
	// 	if ($(this).val().length > 0) {
	// 		$(this).parent().addClass('result');
	// 	} else {
	// 		$(this).parent().removeClass('result');
	// 	}
	// });

	// $('.search-block__ico').on('click', function () {
	// 	var parent = $(this).closest('.search-block');

	// 	parent.removeClass('focus result');
	// 	parent.find('input').val('');
	// });






	// $('.feedback-itm').on('click', function () {
	// 	var items = $(this).closest('.feedback-itm-list').find('.feedback-itm');


	// 	items.removeClass('active');
	// 	$(this).addClass('active');
	// });

	// $('.js-region-btn').on('click', function () {
	// 	// close_all('.js-region-btn,.region-btn-wrap');

	// 	$(this).parent().toggleClass('active');

	// 	return false;
	// });


	
	

	

	// var animate_timeout;
	// $('.ui-slider-handle').on('mousedown', function () {
	// 	clearTimeout(animate_timeout);
	// 	$(this).addClass('animate');
	// });
	// $(document).on('mouseup', function () {
	// 	var itm = $(this);
	// 	animate_timeout = setTimeout(function () {
	// 		$('.ui-slider-handle').removeClass('animate');
	// 	}, 3000);
	// });


	// $('.js-sign-in-modal').on('click', function () {
	// 	var nav = $('#auth .tab-nav'), nav_content = $('#auth .tab-content');
	// 	nav.removeClass('active').eq(0).addClass('active');
	// 	nav_content.removeClass('active').eq(0).addClass('active');
	// });


	// $('.js-sign-up-modal').on('click', function () {
	// 	var nav = $('#auth .tab-nav'), nav_content = $('#auth .tab-content');
	// 	nav.removeClass('active').eq(1).addClass('active');
		// nav_content.removeClass('active').eq(1).addClass('active');
	// });


});


function check_mac() {
	var ua = navigator.userAgent.toLowerCase();

	if (ua.indexOf('mac os') !== -1) {
		console.log('is mac os');
		return true;
	} else {
		console.log('is no mac os');
		return false;
	}

}

// function close_all(not) {
// 	$('.user-menu-block,.js-user-menu-btn,.js-head-cart-btn,.region-btn-wrap,.head-cart-block,.js-noty-btn, .noty-block').not(not).removeClass('active');
// }




function getAllUrlParams(url) {

	// извлекаем строку из URL или объекта window
	var queryString = url ? url.split('?')[1] : window.location.search.slice(1);

	// объект для хранения параметров
	var obj = {};

	// если есть строка запроса
	if (queryString) {

		// данные после знака # будут опущены
		queryString = queryString.split('#')[0];

		// разделяем параметры
		var arr = queryString.split('&');

		for (var i = 0; i < arr.length; i++) {
			// разделяем параметр на ключ => значение
			var a = arr[i].split('=');

			// обработка данных вида: list[]=thing1&list[]=thing2
			var paramNum = undefined;
			var paramName = a[0].replace(/\[\d*\]/, function (v) {
				paramNum = v.slice(1, -1);
				return '';
			});

			// передача значения параметра ('true' если значение не задано)
			var paramValue = typeof (a[1]) === 'undefined' ? true : a[1];


			// если ключ параметра уже задан
			if (obj[paramName]) {
				// преобразуем текущее значение в массив
				if (typeof obj[paramName] === 'string') {
					obj[paramName] = [obj[paramName]];
				}
				// если не задан индекс...
				if (typeof paramNum === 'undefined') {
					// помещаем значение в конец массива
					obj[paramName].push(paramValue);
				}
				// если индекс задан...
				else {
					// размещаем элемент по заданному индексу
					obj[paramName][paramNum] = paramValue;
				}
			}
			// если параметр не задан, делаем это вручную
			else {
				obj[paramName] = paramValue;
			}
		}
	}

	return obj;
}